<template>
  <div class="process-step">
    <div class="process-step-con">
      <div class="img">
          <img src="@/assets/reg1-1.png" alt="">
      </div>
      <span class="tt2" :class="emialInfo.type > 1 ? 'active' : ''">邮箱注册</span>
      <span class="tt3" :class="emialInfo.type == 3 ? 'active' : ''">{{emialInfo.name}}</span>
    </div>
    <div class="process-step-line"></div>
    <div class="process-step-con">
      <div class="img">
                  <img v-if="companyInfo.type == 1" src="@/assets/reg2-1.png" alt="">
                  <img v-else src="@/assets/reg2-2.png" alt="">
      </div>
      <span class="tt2" :class="companyInfo.type > 1 ? 'active' : ''">企业 (机构)信息填写</span>
      <span class="tt3" :class="companyInfo.type == 3 ? 'active' : ''">{{companyInfo.name}}</span>
    </div>
    <div class="process-step-line"></div>
    <div class="process-step-con w115">
      <div class="img">
           <img v-if="personInfo.type == 1" src="@/assets/reg3-1.png" alt="">
           <img v-else src="@/assets/reg3-2.png" alt="">
      </div>
      <span class="tt2" :class="personInfo.type > 1 ? 'active' : ''">申请人信息填写</span>
      <span class="tt3" :class="personInfo.type == 3 ? 'active' : ''">{{personInfo.name}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    emialInfo:{ // 邮箱注册
        type:Object,
        default(){
            return{ // 1 待完成 2 进行中 3 已完成
                type:1,
                name:'待完成'
            }
        } 
    },
    companyInfo:{ // 企业信息填写
        type:Object,
         default(){
            return{ // 1 待完成 2 进行中 3 已完成
                type:1,
                name:'待完成'
            }
        } 
    },
    personInfo:{ // 申请人信息填写
        type:Object,
         default(){
            return{ // 1 待完成 2 进行中 3 已完成
                type:1,
                name:'待完成'
            }
        } 
    }
  },  
  components: {},
  data() {
    return {
       // img1:require("@/assets/reg1-1.png"),
        // img2:require("@/assets/reg1-2.png"),
        // img3:require("@/assets/reg2-1.png"),
       // img4:require("@/assets/reg2-2.png"),
        // img5:require("@/assets/reg3-1.png"),
        //img6:require("@/assets/reg3-2.png"),
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.process-step {
  padding: 0 135px 0 150px;
  display: flex;
  height: 156px;
  background-color: #1f4395;
  background-image: url('../../assets/reg-bg.png');
  
  .process-step-con {
    flex: 0 0 110px;
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img {
      width: 56px;
      height: 56px;
    }
    .tt2 {
      font-size: 16px;
      margin-top: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(154, 191, 242, 1);
      line-height: 20px;
    }
    .tt3 {
      font-size: 12px;
      margin-top: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(154, 191, 242, 1);
      line-height: 20px;
    }
    .active{
      color: #fff;
    }
  }
  .w115{
    flex: 0 0 115px;
    width: 115px;
  }
  .process-step-line {
    margin-top: 78px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 0;
    flex: 1;
    border-top: 1px dashed rgba(154, 191, 242, 1);
  }
}
</style>
