<template>
  <div class="register">
    <AppHeader :type="1"></AppHeader>
    <div class="warp" style="min-height: calc(100% - 165px)">
      <div class="content">
        <Regstep :emialInfo="emialInfo"></Regstep>
        <!-- <img class="topImg" src="@/assets/register.png" alt /> -->
        <el-form class="form" ref="form" :model="form" label-width="120px">
          <el-form-item label="注册邮箱：">
            <el-input
              maxlength="50"
              placeholder="请输入邮箱"
              v-model="form.email"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱验证码：">
            <el-input
              maxlength="6"
              placeholder="请输入验证码"
              v-model="form.vertify"
            >
            </el-input>
            <i v-show="!isInter" @click="getVertify" class="suf suffix"
              >获取邮箱验证码</i
            >
            <i v-show="isInter" class="suf suffix2">({{ inter }}s)</i>
          </el-form-item>
          <el-form-item label="密码：">
            <el-input
              maxlength="64"
              type="password"
              placeholder="8-16位，必须包含数字及字母，大小写均可，支持特殊字符"
              v-model.trim="form.newPass"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码：">
            <el-input
              maxlength="64"
              type="password"
              placeholder="确认密码"
              v-model.trim="form.newPassComfirm"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="主体类型：" class="user_type">
            <el-select v-model="form.user_type" placeholder="请选择">
              <el-option
                v-for="item in subjectTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
          </el-select>
          </el-form-item> -->
        </el-form>

        <div class="agree">
          <el-checkbox v-model="checked"> 同意 </el-checkbox>
          <span
            @click="centerDialogVisible = true"
            style="font-size: 14px; color: #2b4fa5; cursor: pointer"
            >《至信链平台服务协议》</span
          >
          <span>、</span>
          <span
            @click="yinsiDialogVisible = true"
            style="font-size: 14px; color: #2b4fa5; cursor: pointer"
            >《隐私政策》</span
          >
        </div>

        <div class="footBtn">
          <el-button type="primary" @click="onSubmit">注册</el-button>
        </div>
        <p @click="toHome" class="fontText">使用已有账号登录</p>
      </div>
    </div>
    <AppFooter></AppFooter>

    <el-dialog
      title="《至信链平台用户服务协议》"
      width="65%"
      custom-class="center-dialog"
      :visible.sync="centerDialogVisible"
    >
      <div class="protocol-con">
        <p class="p1">
          尊敬的用户：
          感谢您选择至信链平台服务。《至信链平台用户服务协议》（以下简称“本协议”）由【北京枫调理顺科技发展有限公司】（以下简称“枫调理顺”或“我们”）和用户（以下简称“您”或“用户”）签订，本协议在您与公司之间具有合同上的法律效力。我们在此特别提醒您在使用至信链平台服务之前，请认真阅读《至信链平台用户服务协议》及后文提到的相关协议。
          当您按照注册页面提示填写信息、阅读并同意协议且完成全部注册程序后，即表示您已充分阅读、理解并接受协议的全部内容。如您使用至信链开放平台服务，要遵循《中华人民共和国网络安全法》、《区块链信息服务管理规定》、《至信链平台服务协议》及至信链开放平台其他相关协议。如您在使用至信链开放平台服务过程中与其他用户或其它任意第三方发生争议的,将依照您与其他用户或其它任意第三方达成的协议处理。<span
            class="txt-strong"
            >阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止注册程序。</span
          >
        </p>
        <!-- con 是一块内容 -->
        <div class="con">
          <h3>1 注册声明</h3>
          <h4>1.1【审慎阅读】</h4>
          <p>
            <span class="txt-strong"
              >请您务必审慎阅读、充分理解协议中相关条款内容，特别是：</span
            >
          </p>
          <ul>
            <li class="txt-strong">（1）与您约定免除或限制责任的条款；</li>
            <li class="txt-strong">（2）与您约定法律适用和争议解决的条款；</li>
            <li class="txt-strong">（3）其他以粗体下划线标识的重要条款。</li>
          </ul>
          <p>
            <span class="txt-strong"
              >如您对协议有任何疑问，可向平台客服咨询。</span
            >
          </p>
          <h4>1.2【签约行为】</h4>
          <p>
            <span class="txt-strong"
              >当您按照页面提示同意本协议并完成服务开通流程后，即表示您已充分阅读、理解并接受本协议的全部内容。</span
            >若您不同意本协议的任意内容，或者无法准确理解至信链平台对条款的解释，请不要进行后续操作；若您注册成为至信链平台用户，则表示您对本协议的全部内容已充分阅读并认可和同意遵守。同时，承诺遵守中国法律、法规、规章及其他规范性文件的规定，如有违反而造成任何法律后果，您将以本人名义/企业名义独立承担所有相应的法律责任。
          </p>
          <h4>1.3【用户限制】</h4>
          <p>
            <span class="txt-strong"
              >您注册至信链平台账号时，应具备中华人民共和国法律规定的与该注册行为相适应的民事行为能力。</span
            >若您不具备前述与行为相适应的民事行为能力，则您或您的监护人应依照法律规定承担因此而导致的一切法律后果。无民事行为能力人使用至信链平台或限制民事行为能力人超过其民事权利或行为能力范围使用相关服务的，造成的一切后果由其父母或监护人负责。
          </p>
          <h4>1.4【协议范围】</h4>
          <p>
            <span class="txt-strong"
              >本协议内容包括本文全部条款及已经发布的或将来可能发布的各类规则，包括但不限于《至信链平台隐私政策》等为本协议不可分割的一部分，与协议正文具有同等法律效力。</span
            >
          </p>
          <h4>1.5【协议更新】</h4>
          <p>
            至信链平台保留随时自行决定修改、更新、添加或删除本服务协议条款相关内容的权利，且按本协议约定的方式进行通知，您应当及时关注并查阅。若您在本服务协议条款相关内容修改、更新、添加或删除后，仍访问或使用本平台的，视为您完全同意并接受修改、更新、添加或删除后的全部内容。至信链平台的通知、公告、声明、规则、管理办法或其他规则内容属于本协议的组成部分，平台进行公示后即视为您已阅读并接受，对您发生法律效力。<span
              class="txt-strong"
              >如您不接受对本协议的修改，请立即停止使用至信链平台提供的各项服务。</span
            >
          </p>
        </div>

        <!-- 模版  <p><span class="txt-strong"></span></p>  -->

        <div class="con">
          <h3>2 定义</h3>
          <h4>2.1【至信链平台】</h4>
          <p>
            至信链开放平台（以下简称“至信链平台”），是由北京枫调理顺科技发展有限公司（以下简称“枫调理顺”）负责运营，专业提供包括但不限于电子数据区块链技术存证等服务，具体服务内容由至信链平台根据实际情况提供。
          </p>
          <h4>2.2【平台用户】</h4>
          <ul>
            <li>
              （1）至信链平台用户是指自愿接受本协议及至信链平台全部规则约束，依照平台相关程序经过注册成为平台的使用者，从而有资格享受平台所提供相关服务的自然人、法人或其他组织等。
            </li>
            <li>
              （2）至信链平台用户分为政府用户、企业用户、个人用户。政府用户指政府机构或部门以《至信链平台认证公函》指定的账户用户。企业用户指企业法人或代表以《至信链平台认证公函》指定的账户用户。个人用户指个人以《至信链平台认证公函》申请的账户用户。
            </li>
          </ul>
          <h4>2.3【区块链技术存证服务】</h4>
          <p>
            本协议所称电子数据区块链技术存证服务，指以可信时间戳、hash值校验以及区块链等技术形式，对电子证据进行固定化、防篡改存储的技术服务
          </p>
        </div>

        <div class="con">
          <h3>3 账号注册及管理</h3>
          <h4>3.1【账户注册】</h4>
          <p>
            当您按照注册页面提示正确、完整的填写信息、提供相关具有法律效力的材料、阅读并同意本协议且完成全部注册程序后，本协议即生效，您可获得至信链平台的账户并成为至信链平台用户。您成为至信链平台用户后，即受本协议的约束，当您在使用至信链平台的特殊服务和产品时，仍应同意和接受相关服务协议后方能使用。
          </p>
          <h4>3.2【账户转让】</h4>
          <p>
            由于用户账户关联用户的信用信息，仅当由法律明文规定、司法裁定或经至信链平台书面同意，并符合至信链平台规则规定的账户转让流程的情况下，您方可进行账户的转让。否则，您的账户不得以任何方式转让，您的账户名下所进行的任何行为均视为您进行的个人行为，由此产生的一切责任均由您自己承担。
          </p>
          <h4>3.3【实名认证】</h4>
          <p>
            法律并未赋予至信链平台强制要求用户进行实名认证的权利，但为使您更好地体验至信链平台的各项服务，至信链平台建议您按照至信链平台的要求及我国法律规定完成实名认证。您因未进行实名认证而造成的一切法律责任及后果，由您自行承担，与至信链平台无涉。
          </p>
          <h4>3.4【账户管理】</h4>
          <p>
            您设置的用户名不得违反国家法律法规关于用户的管理规定，否则至信链平台有权收回或禁用您的账户。若您的账户因违反相关规定遭至信链平台禁用，您可以申请更改用户名，经至信链平台审核通过后恢复使用。
          </p>
          <h4>3.5【账户信息】</h4>
          <p>
            您应按至信链平台的要求填写注册信息、提交资料或其他身份信息（包括但不限于您的姓名/企业名称、身份证/营业执照、联系电话、联系地址及电子邮件等），并应确保所填写的信息或资料真实、完整、准确、有效，如有变化，应及时更新相关信息或资料。如因您提供的资料不准确、不真实或未及时变更有关信息或资料所导致的法律后果和损失，包括对至信链或任何第三方的相关法律责任，将由您自行承担。
          </p>
          <h4>3.6【账户安全】</h4>
          <p>
            您的账户由您自行设置并妥善保管，因黑客行为或您保管疏忽导致账号、密码遭他人非法使用或因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。如您发现账号遭他人非法使用，应立即通知至信链平台。如因您未及时通知至信链平台，您应对您账户项下所有行为结果（包括但不限于在线签署各类协议、发布信息、著作权授权、著作权购买等）负责，后期如因此给您或第三方造成损失的，均由您自行承担。
          </p>
        </div>

        <div class="con">
          <h3>4 用户承诺与保证</h3>
          <h4>4.1【自愿注册】</h4>
          <p>您自愿申请成为至信链平台用户并遵守至信链平台的各项规则和机制。</p>
          <h4>4.2【合法合规使用】</h4>
          <ul>
            <li>
              （1）您使用至信链平台各项服务的，应遵守至信链平台的各项服务规范，包括但不限于《至信链平台服务协议》等。
            </li>
            <li>
              （2）您确认已阅读并自愿遵守我国的法律法规、各项部门规章制度及至信链平台相关规则，并接受枫调理顺关于至信链平台相关业务规则的修订，修订内容以在至信链平台官方网站发布公告等形式通知您。
            </li>
          </ul>
          <h4>4.3【用户发布内容禁止】</h4>
          <p>
            <span class="txt-strong">
              您保证对提供的内容负全部责任，不得在至信链平台上传含有下列内容之一的存证信息，否则至信链平台有权自行处理并不予以通知，且相关责任和后果由您自行承担：</span
            >
          </p>
          <ul>
            <li>
              <span class="txt-strong>">（1）违反宪法确定的基本原则的；</span>
            </li>
            <li>
              <span class="txt-strong>"
                >（2）危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</span
              >
            </li>
            <li>
              <span class="txt-strong>">（3）损害国家荣誉和利益的； </span>
            </li>
            <li>
              <span class="txt-strong>"
                >（4）煽动民族仇恨、民族歧视，破坏民族团结的；</span
              >
            </li>
            <li>
              <span class="txt-strong>"
                >（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</span
              >
            </li>
            <li>
              <span class="txt-strong>"
                >（6）散布谣言，扰乱社会秩序，破坏社会稳定的；
              </span>
            </li>
            <li>
              <span class="txt-strong>"
                >（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
              </span>
            </li>
            <li>
              <span class="txt-strong>"
                >（8）侮辱或者诽谤他人，侵害他人合法权益的；
              </span>
            </li>
            <li>
              <span class="txt-strong>"
                >（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
              </span>
            </li>
            <li>
              <span class="txt-strong>">（10）以非法民间组织名义活动的； </span>
            </li>
            <li>
              <span class="txt-strong>"
                >（11）含有法律、行政法规禁止的其他内容的。</span
              >
            </li>
          </ul>
          <h4>4.4【账户妥善保管】</h4>
          <p>
            您承诺将妥善保管账号、密码及相关密保文件，若因泄密而造成的损失，您将自行承担相应责任。
          </p>
          <h4>4.5【用户行为责任承担】</h4>
          <p>
            您确保将按照我国法律法规及至信链平台规则行使通过至信链平台获得的一切权利，因您不当使用而造成的一切后果，由您自行承担。
          </p>
        </div>

        <div class="con">
          <h3>5 至信链平台承诺及保证</h3>
          <h4>5.1【合规运营】</h4>
          <p>
            至信链平台承诺其严格遵守国家法律法规、各项部门规章制度的规定，并愿意接受本协议所有条款的约束。
          </p>
          <h4>5.2【用户信息保护】</h4>
          <p>
            至信链平台承诺在现有技术条件下，尽一切合理努力运用相关安全技术和管理措施对您的账户信息、资料履行保密义务，免遭未经授权的访问、使用或披露，但下列情况除外：
          </p>
          <ul>
            <li>（1）事先获得您的明确授权、同意或许可（含默示行为）；</li>
            <li>（2）根据中华人民共和国法律或行政法规、规章的要求；</li>
            <li>
              （3）根据中华人民共和国国家安全机构、公安部门或其他权力机构的要求，或根据相应的法律程序的要求；
            </li>
            <li>
              （4）在紧急情况下为维护用户、其它社会个体或公众的安全及秩序的需要，出于维护社会公共利益的目的；
            </li>
            <li>（5）为维护至信链平台的合法权益。</li>
            <li>
              （6）
              为提升至信链平台的服务质量，至信链平台可能会与第三方合作为您提供相关的服务，此类合作可能需要包括但不限于至信链平台用户数据与第三方用户数据的互通，在此情况下，您知晓并同意如该第三方同意承担与至信链平台同等的保护您隐私的责任，则至信链平台有权将您的账户信息、资料、至信链平台活动等提供给该第三方，并与该第三方约定数据仅为双方合作的至信链平台服务之目的使用；并且，至信链平台对该等第三方使用数据的行为进行监督和管理，尽一切合理努力保护您信息的安全性。尽管对您的账户等信息保护做了极大的努力，但是仍然不能保证现有的安全技术措施可以使您的技术信息等免受任何形式的损失。
            </li>
          </ul>
        </div>
        <div class="con">
          <h3>6 免责及责任限制</h3>
          <h4>6.1【信息完整性】</h4>
          <p>
            您有义务在注册时提供自己的真实、最新、完整的资料，并保证电子邮件地址、联系电话、联系地址、邮政编码等内容的最新、有效性及安全性。您有义务维护并随时更新您的个人资料，确保其真实、最新、有效及完整。若您提供任何错误、虚假、过时或不完整的资料，或者本平台有合理的理由怀疑您提供的为错误、虚假、过时或不完整的资料时，本平台有权暂停、锁定或终止您的账号，并拒绝您使用本平台部分或全部服务。在此情况下，本平台不承担任何责任，并且您同意自行承担因此所产生的直接或间接的任何支出或损失。
          </p>
          <h4>6.2【公司对用户第三方应用上行为的免责】</h4>
          <p>
            <span class="txt-strong"
              >用户在第三方应用上操作产生的风险和后果由用户自行承担。</span
            >
            您理解至信链平台仅作为您数字藏品管理的工具，公司不能控制第三方应用提供的产品及服务的质量、安全或合法性，信息的真实性或准确性，以及相对方履行其在与您签订的协议项下的各项义务的能力。所有您在第三方应用进行的交易行为系您的个人行为，有约束力的合同关系在您和您的相对方之间建立，与至信链平台无关。公司提醒您应该通过自己的谨慎判断确定登录第三方应用及相关信息的真实性、合法性和有效性。
          </p>
          <p>
            <span class="txt-strong"
              >您与任何第三方交易所产生的风险亦应由您自行承担。公司可能同时为您及您的交易对手方提供服务，您同意对公司可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张公司在提供服务时存在法律上的瑕疵，也不因此而加重公司的责任或注意义务。</span
            >
          </p>
          <h4>6.3【用户服务限制】</h4>
          <p>
            在如下情况，至信链平台有权中止、终止对您提供部分或全部服务而不承担任何责任：
          </p>
          <ul>
            <li>
              <span class="txt-strong"
                >（1）在至信链平台未向您收费的情况下，至信链平台可自行全权决定以合理理由
                (包括但不限于您已违反本协议的字面意义和精神，或您以不符合本协议的字面意义和精神的方式行事)
                终止您的登录权限、账户地址 (或其任何部分)
                或您对服务的使用。</span
              >
            </li>
            <li>
              <span class="txt-strong"
                >（2）在出现下列任一情况时，至信链平台可立即发出警告，中止或终止您的账号和服务，删除您的任何现有信息，以及您在本平台上展示的任何其他资料：（a）您违反本协议；（b）至信链平台无法核实或鉴定您向至信链平台提供的任何资料；（c）至信链平台相信您的行为可能会使您、至信链平台其他用户或通过至信链平台提供服务的第三者服务供应商发生任何法律责任；（d）发现您从事涉及平台的诈骗等违法违规活动。对于（a）、（b）、(c)三项，至信链平台不限制用户采取相关补救措施。</span
              >
            </li>
            <li>
              <span class="txt-strong"
                >（3）对于本协议规定的采取的中止或终止措施，至信链平台不对结果承担任何责任。</span
              >
            </li>
            <li>
              <span class="txt-strong"
                >（4）根据本协议的任何规定中止或终止您使用服务之措施，至信链平台将本着审慎的态度实施，并将履行告知义务，即使已通知您不能继续使用至信链平台的服务，但在您的账号内的所有相关资料，至信链平台将继续为您保存，除本协议另有约定外，可以查询和为您提供相关文件内容的证明。</span
              >
            </li>
            <li>
              <span class="txt-strong"
                >（5）由于互联网本身所具有的不稳定性，至信链平台无法保证服务不会中断。系统因有关状况无法正常运作，使用户无法使用任何至信链平台服务或使用至信链平台服务受到任何影响时，至信链平台对用户或第三方不负任何责任，前述状况包括但不限于：（a）至信链平台系统停机维护期间；（b）电信设备出现故障不能进行数据传输的；（c）由于黑客攻击、网络供应商技术调整或故障、平台升级、银行方面的问题等原因而造成的至信链平台服务中断或延迟；（d）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成至信链平台系统障碍不能执行业务的；(e)
                因时间滞后、区块链系统不稳定等原因导致公司拷贝用户在区块链上的交易记录发生偏差；
              </span>
            </li>
            <li>
              <span class="txt-strong"
                >（6）因用户的过错导致的任何损失，该过错包括但不限于：操作不当、遗忘或泄露密码、密码被他人破解、用户使用的计算机系统被第三方侵入、用户委托他人代理签署时他人恶意或不当操作而造成的损失，至信链平台不承担任何责任。</span
              >
            </li>
          </ul>
          <h4>6.4【暂停服务】</h4>
          <p>您理解存在如下情形时，公司将暂停提供服务：</p>
          <ul>
            <li>
              <span class="txt-strong"
                >(1)
                因设备、区块链系统维修、升级、故障和通信中断等技术原因而中断业务；
              </span>
            </li>
            <li>
              <span class="txt-strong"
                >(2)
                因台风、地震、海啸、洪水、停电、战争或恐怖袭击等不可抗力因素，病毒、木马、黑客攻击、系统不稳定或政府行为等原因，造成公司系统不能提供服务或公司合理认为继续提供服务会有较大风险的；</span
              >
            </li>
            <li>
              <span class="txt-strong"
                >(3) 发生公司无法控制或合理预见的其他情形。</span
              >
            </li>
            <li>
              <span class="txt-strong"
                >(4)
                当您出现如下情况时，公司可单方面中止或终止您使用至信链平台的部分或全部功能:
                （a）用户死亡； （b）盗用他人的平台信息或移动设备；
                （c）填写个人信息时提供虚假信息；
                （d）拒绝公司为提升至信链平台功能而发起的强制更新操作；
                （e）将至信链平台用于违法或犯罪活动；
                （f）妨碍其他用户正常使用； （h）伪称公司的工作人员或管理人员；
                （i）攻击、侵入、更改或以任何其他方式威胁公司计算机系统的正常运作；
                （j）利用至信链平台宣传垃圾广告；
                （k）散布谣言，损害公司和至信链平台商誉；
                （l）违法行为，其他违反本协议的行为，及公司合理认为应当暂停功能的情形。
              </span>
            </li>
          </ul>
          <h4>6.5【用户行为限制】</h4>
          <p>
            至信链平台可在任何时候为任何原因变更本服务或删除其部分功能。至信链平台可在任何时候取消或终止对用户的服务。至信链平台取消或终止服务的决定不需要说明理由或通知用户。一旦服务取消或终止，用户使用本服务的权利立即终止，用户在本服务中储存的任何信息可能无法恢复。用户应自行备份、储存相关的所有信息。至信链平台不负责返还用户所有相关的数据信息。
          </p>

          <h4>6.6【协议终止】</h4>
          <ul>
            <li>
              （1）至信链平台有权根据情况自行考虑来决定终止对您的账号或服务之使用，终止理由包括但不限于长时间未使用、违反法律法规、违反本协议及至信链平台的其他规则等。
            </li>
            <li>
              （2）终止服务后，至信链平台有权将您在至信链平台内的任何内容加以移除并删除。至信链平台对于终止您的账号及相关信息和文件被关闭或删除均不承担任何责任。
            </li>
          </ul>
        </div>

        <div class="con">
          <h3>7 通知</h3>
          <h4>7.1【通知方式】</h4>
          <p>
            您同意至信链平台以以下合理任一的方式发送的通知，均视为成功送达通知：（1）官方网站公示的文案；（2）网站及客户端推送的消息；（3）根据您预留于至信链平台的联系方式发出的电子邮件、短信、函件等。
          </p>
          <h4>7.2【送达】</h4>
          <p>
            <span class="txt-strong"
              >本平台的通知如以公示方式做出，一经在本平台公示即视为已经送达。</span
            >除此之外，其他向您个人发布的具有专属性的通知将由本平台向用户注册后在本平台绑定的手机号码发送，一经发送即视为已经送达。请您密切关注手机中的短信信息。<span
              class="txt-strong"
              >因信息传输或您变更联系方式但未在本平台的注册信息中填写您变更后的联系方式等原因导致您未在前述通知发出后收到该等通知的，本平台不承担责任。</span
            >
          </p>
          <h4>7.3【通知内容】</h4>
          <p>
            至信链平台将通过您提供的联系方式送达各类通知，此类通知有可能对您的权利义务产生重大影响，请及时查阅、知悉。您同意至信链平台利用在本平台登记的联系方式与您联络并向您传递相关的信息，包括但不限于行政管理方面的通知、产品信息、有关您使用本平台的通讯以及针对性的广告等。
          </p>
        </div>
        <div class="con">
          <h3>8 出口管制</h3>
          <h4>8.1【出口管制说明】</h4>
          <p>
            您对于至信链平台之使用应遵守所有相关的经济与贸易制裁以及进出口管制规定，包括但不限于所有由联合国以及中国、美国等任何国际组织、国家及所制定的制裁及进出口管制决议、条例与规定等（以下统称“进出口管制规定”）。
          </p>
          <h4>8.2【用户出口承诺】</h4>
          <p>
            <span class="txt-strong"
              >您承诺不会以任何方式使得至信链违反进出口管制规定，导致至信链被采取任何制裁、处罚措施或者因此遭受损失。因您的行为违反进出口管制规定或本协议导致至信链遭受指控或产生损失，您同意承担责任并赔偿至信链遭受的损失。</span
            >
          </p>
        </div>
        <div class="con">
          <h3>9 违约责任</h3>
          <h4>9.1【用户违约】</h4>
          <p>违约认定发生如下情形之一的，视为您违约</p>
          <ul>
            <li>（1）使用至信链平台服务时违反有关法律法规规定的；</li>
            <li>（2）违反本协议约定的。</li>
          </ul>
          <h4>9.2【违约处理措施】</h4>
          <ul>
            <li>
              （1）您在至信链平台内发布的信息构成违约的，我们可根据相应规则立即对相应信息进行删除、屏蔽处理。
            </li>
            <li>
              （2）您在至信链平台实施的行为，或虽未在至信链平台实施但对至信链平台及其用户产生影响的行为构成违约的，我们可依据相应规则对您执行账户限制、中止向您提供部分或全部服务等处理措施。如您的行为构成根本违约的，您同意我们可查封您的账户并终止向您提供服务，您将无法再基于您的账户登录使用相关服务。
            </li>
            <li>
              （3）至信链平台可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在至信链平台内予以公示，公示信息包括您账号名称、违约、违法行为、处理结果等。
            </li>
          </ul>
          <h3>9.3【赔偿责任】</h3>
          <p>
            <span class="txt-strong"
              >因您违反本协议或经在此提及而纳入本协议的其他文件，或因您违反了法律、法规、规章、政府规范性文件或侵害了第三方的权益，
              致使枫调理顺公司及枫调理顺公司第三方服务商遭受损失（包括对外支付的违约金、赔偿款、和解款、罚款、律师费、诉讼费、商誉损失等直接经济损失及间接经济损失）或遭受第三人主张权利而对第三人承担金钱给付等义务的，您应赔偿枫调理顺公司及枫调理顺公司第三方服务商的上述全部损失。</span
            >
          </p>
        </div>
        <div class="con">
          <h3>10 法律适用与争议解决</h3>
          <h4>10.1【适用法律】</h4>
          <p>
            本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项没有法律规定或规定不明确，则应参照通用的国际商业惯例和行业惯例。
          </p>
          <h4>10.2【管辖法院】</h4>
          <p>
            <span class="txt-strong"
              >因本协议或您使用至信链平台服务所引起或与其有关的任何争议，由我们与您协商解决，协商不成时，任何一方均可向北京枫调理顺科技发展有限公司所在地法院提起诉讼</span
            >
          </p>
        </div>
        <div class="con">
          <h3>11 其他</h3>
          <h4>11.1【标题说明】</h4>
          <p>
            各条款前所列索引标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释
          </p>
          <h4>11.2【协议可分性】</h4>
          <p>本协议部分条款被认定为无效时，不影响本协议其他条款的效力。</p>
          <h4>11.3【协议持续性】</h4>
          <p>
            本协议自您勾选并成功注册为本平台用户之日起生效，除非本平台终止本协议或者用户丧失本平台用户资格，否则本协议始终有效。本服务协议终止并不免除用户根据本协议或其他有关协议、规则所应承担的义务和责任。
          </p>
          <h4>11.4【联系我们】</h4>
          <p>
            如您对本用户协议有任何问题、意见或建议，或者需要就个人信息安全进行投诉、举报的，请联系平台客服解决。
          </p>
        </div>
      </div>
    </el-dialog>

    <!-- 隐私政策 start -->
    <el-dialog
      title="《隐私政策》"
      width="65%"
      custom-class="center-dialog"
      :visible.sync="yinsiDialogVisible"
    >
      <div class="protocol-con">
        <!-- 每一块是一个con -->
        <!-- <div class="con">
              <h3>1 注册声明</h3>
              <h4>1.1【审慎阅读】</h4>
              <p><span class="txt-strong">请您务必审慎阅读、充分理解协议中相关条款内容，特别是：</span></p>
                <ul>
                      <li class="txt-strong">（1）与您约定免除或限制责任的条款； </li>
                      <li class="txt-strong">（2）与您约定法律适用和争议解决的条款； </li>
                      <li class="txt-strong">（3）其他以粗体下划线标识的重要条款。 </li>
                </ul>
                <p><span class="txt-strong">如您对协议有任何疑问，可向平台客服咨询。</span></p>
                <h4>1.2【签约行为】</h4>
                <p><span class="txt-strong">当您按照页面提示同意本协议并完成服务开通流程后，即表示您已充分阅读、理解并接受本协议的全部内容。</span>若您不同意本协议的任意内容，或者无法准确理解至信链平台对条款的解释，请不要进行后续操作；若您注册成为至信链平台用户，则表示您对本协议的全部内容已充分阅读并认可和同意遵守。同时，承诺遵守中国法律、法规、规章及其他规范性文件的规定，如有违反而造成任何法律后果，您将以本人名义/企业名义独立承担所有相应的法律责任。</p>
                <h4>1.3【用户限制】</h4>
                <p><span class="txt-strong">您注册至信链平台账号时，应具备中华人民共和国法律规定的与该注册行为相适应的民事行为能力。</span>若您不具备前述与行为相适应的民事行为能力，则您或您的监护人应依照法律规定承担因此而导致的一切法律后果。无民事行为能力人使用至信链平台或限制民事行为能力人超过其民事权利或行为能力范围使用相关服务的，造成的一切后果由其父母或监护人负责。</p>
                <h4>1.4【协议范围】</h4>
                <p><span class="txt-strong">本协议内容包括本文全部条款及已经发布的或将来可能发布的各类规则，包括但不限于《至信链平台隐私政策》等为本协议不可分割的一部分，与协议正文具有同等法律效力。</span></p>
                <h4>1.5【协议更新】</h4>
                <p>至信链平台保留随时自行决定修改、更新、添加或删除本服务协议条款相关内容的权利，且按本协议约定的方式进行通知，您应当及时关注并查阅。若您在本服务协议条款相关内容修改、更新、添加或删除后，仍访问或使用本平台的，视为您完全同意并接受修改、更新、添加或删除后的全部内容。至信链平台的通知、公告、声明、规则、管理办法或其他规则内容属于本协议的组成部分，平台进行公示后即视为您已阅读并接受，对您发生法律效力。<span class="txt-strong">如您不接受对本协议的修改，请立即停止使用至信链平台提供的各项服务。</span></p>
          </div> -->

        <p class="p1">
          感谢您选择和使用至信链平台服务！为方便您注册、登录、使用相关服务，以及为您提供更加个性化的用户体验和服务，您在使用至信链平台服务时，我们可能会收集和使用您的相关信息。至信链平台非常重视用户信息的保护，我们将按照法律法规要求，采取相应的安全保护措施。我们希望通过本《至信链平台隐私政策》（以下简称“政策”）向您说明，在您使用至信链平台提供的产品、服务时，我们如何收集、使用、存储和共享您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方法。
        </p>
        <p class="p1">
          本政策与您所使用的至信链平台服务息息相关，希望您仔细阅读。您以在线勾选等任何方式确认接受本政策或使用至信链平台服务的，即视为您已充分阅读、理解并同意接受本政策的约束，本政策即在您与至信链平台之间产生法律效力。
        </p>
        <div class="con">
          <h3>1 本政策的适用范围</h3>
          <h4>1.1【公司信息】</h4>
          <p>
            本政策适用于【北京枫调理顺科技发展有限公司】（以下简称“枫调理顺”或“我们”）通过至信链平台官网以及至信链平台其他平台提供的产品和服务，本政策另有约定除外。
          </p>
          <h4>1.2【服务范围】</h4>
          <p>
            在您使用、获取或购买至信链平台提供的任何产品或服务并且 (1)
            在您将您的个人信息提供给我们，并按照我们的处理目的而使用的情况下，我们将根据《至信链平台隐私政策》收集并处理您的个人信息；或
            (2)
            在您委托我们处理您的个人信息的情况下，我们将根据《至信链平台隐私政策》处理您的个人信息。
          </p>
          <h4>1.3【第三方提供的服务】</h4>
          <p>
            本政策不适用于其他第三方向您提供的产品和服务，例如至信链平台上的第三方服务商向您提供产品或服务时，需由您与服务商另行确定有关信息、数据等的使用政策、服务协议等。
          </p>
          <h4>1.4【用户信息】</h4>
          <p>
            本政策适用于您注册至信链平台账户所提交的所有信息、资料等，以及您使用至信链平台服务时提交的或至信链平台为了向您提供服务而收集的信息（客户数据除外），前述信息统称为“用户信息”。例如，名称、营业执照、身份证号码、联系方式等。外。
          </p>
          <h4>1.5【客户数据】</h4>
          <p>
            客户数据是指您使用至信链平台服务（包括通用API、SDK等）过程中以上传、传输、存储，或者以分析、分发等任何方式处理的各类数据，包括但不限于您在日常经营活动中所收集、产生的用户的个人信息等。
          </p>
          <ul>
            <li>
              （1）
              您应确保客户数据是依法收集、使用（包括但不限于委托处理等）的，您不会也不曾以任何方式侵犯任何个人或实体的合法权利。
            </li>
            <li>
              （2）您保证有权使用至信链平台服务对客户数据进行存储、传输、分析和分发等任何处理，我们将按照您的指令、委托处理您的客户数据。
            </li>
            <li>
              （3）您理解并同意，中国及其他国家、地区可能对数据存储、出境等有相关的规定，在您使用至信链平台服务对客户数据进行存储、传输等操作前，您应当遵照相关地区适用的法律法规进行充分、必要的评估、审批，确保符合相关规定。
            </li>
            <li>
              （4）您可以通过《至信链平台服务协议》知悉更多关于客户数据的相关政策。
            </li>
          </ul>
          <h4>1.6【协议变动】</h4>
          <p>
            <b
              >至信链平台有权根据需要不定时地制定、修改本协议或各类规则，如本政策及规则有任何变更，一切变更以本平台最新公布的内容为准。</b
            >用户有权拒绝接受新的政策和规则，但必须停止使用至信链平台提供的各项服务。
          </p>
        </div>

        <div class="con">
          <h3>2 我们收集的信息</h3>
          <h4>2.1【我们收集信息的方式】</h4>
          <p>我们可能通过以下几种方式收集您的信息：</p>
          <ul>
            <li>
              （1）您主动提交，例如您或您授权的主体在注册账号、完善信息等情况下填写、上传的相应信息。
            </li>
            <li>
              （2）您授权他方或您的服务商代为提交、记录的信息，例如您委托特定服务商在至信链平台系统中填写、上传的您的相应信息。
            </li>
            <li>
              （3）我们在您使用我们的服务过程中以主动收集或产生相应的记录等方式收集的信息，例如在您登录至信链平台账号时后台记录下的登录时间，您使用特定至信链平台服务的操作记录等。
            </li>
          </ul>

          <h4>2.2【身份信息的内容】</h4>
          <p>
            为完成至信链平台账号的注册，您需要根据您选择的注册方式提交真实、合法、有效的信息，设置、确认登录密码等，包括但不限于微信号码、QQ
            号码、电子邮箱、微信公众号、用户名、姓名/名称、联系人、联系电话、联系地址、银行账户信息、工商登记信息等。
          </p>
          <h4>2.3【第三方平台账号授权】</h4>
          <p>
            如果您使用其他平台的账号登录至信链平台或将其他平台的账号与至信链平台账号进行关联、绑定，您将授权至信链平台获得您其他平台账号的相关信息，例如微信号码、QQ
            号码、邮箱地址、微信公众号、手机号码、昵称、头像、性别等。
            若您通过其他平台登录或者以账号关联等方式使用至信链平台服务，且该类您所使用的服务要求进行实名认证的，您需要在至信链平台另行进行实名认证或者将原有的实名认证信息同步授权给至信链平台，以便顺利使用该类服务。
          </p>

          <h4>2.4【实名认证的信息】</h4>
          <p>
            在您进行实名认证时，您需要根据您所选择的认证类型提供对应的信息，例如姓名、身份证、名称、营业执照、对公银行账号、通讯地址、行业信息、手机号、人脸信息、联系方式等。
            实名认证是判断账号归属的重要依据，为了您的账号安全，请务必审慎选择认证方式，提交合法、真实、有效的信息。
            部分服务、活动仅支持实名认证用户使用或参与，若您未认证，将无法使用或参与前述服务和活动。
          </p>
          <h4>2.5【注册账号妥善保管】</h4>
          <p>
            注册和管理账号涉及的信息与您管理、使用至信链平台账号、至信链平台服务等紧密相关（例如验证您的身份等），请您务必谨慎确认和维护；前述信息发生变化时，请及时进行更改，例如发生管理人员变动时，应提前对账号密码、联系人、联系电话等进行变更。
          </p>
          <h4>2.6【服务中产生的信息】</h4>
          <p>
            为提供持续服务和保证服务质量所需，在您使用至信链平台服务期间，我们将记录并保存您登录和使用服务的相关信息（但不会包含进入该服务后的操作），包括但不限于：
          </p>
          <ul>
            <li>
              （1）
              操作记录，我们会接收并记录您使用至信链平台服务的操作记录及相关信息，例如身份
              ID、操作时间、操作的对象信息（资源 ID、区域、所属产品等）、IP
              地址、浏览器的类型、使用的语言、设备型号、操作系统版本及网页浏览/检索记录等。
            </li>
            <li>
              （2）合同信息，如果您需要申请线下交付或进行产品测试等，为履行合同所必需，您应提供联系人、联系方式、地址、开票信息、订单等必要信息。
            </li>
            <li>（3）其他信息，例如工单记录、咨询沟通记录等。</li>
          </ul>
        </div>

        <div class="con">
          <h3>3 我们如何使用 Cookie 及相关技术</h3>
          <h4>3.1【使用方式】</h4>
          <p>
            我们可能会通过 Cookie 和其他相关技术收集和使用您的信息。我们使用
            Cookie 的具体用途包括：
          </p>
          <ul>
            <li>
              （1） 记住您的身份。例如：Cookie
              有助于我们辨认您作为我们的注册用户的身份。
            </li>
            <li>
              （2）
              分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括但不限定制化页面、推荐等。
            </li>
          </ul>
          <h4>3.2【Cookie服务政策】</h4>
          <ul>
            <li>
              （1）您可以通过浏览器设置拒绝或管理 Cookie。但请注意，如果停用
              Cookie，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。
            </li>
            <li>（2）通过至信链平台 Cookie 记录的有关信息，将适用本政策。</li>
          </ul>
        </div>

        <div class="con">
          <h3>4 我们如何保存您的信息</h3>
          <p>
            为了向您提供优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途：
          </p>
          <ul>
            <li>（1）向您提供服务。</li>
            <li>
              （2）满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。
            </li>
            <li>
              （3）服务优化和开发。例如，我们会根据至信链平台系统响应您的需求时所产生的信息，优化我们的服务。例如，为履行本政策或您使用的其他相关产品、服务的服务协议、规则等的约定。
            </li>
            <li>
              （4）
              保护至信链平台、至信链平台用户和至信链平台的合作伙伴。例如，我们会将您的信息用于身份验证、安全防范、诈骗监测，以及判断您的账号安全等用途。例如，您在使用安全功能或其他类似服务时，我们会对恶意程序或病毒进行检测，或为您识别诈骗信息。例如，在处理相关纠纷、投诉时，为相关当事人提供有关信息以便更好的促进纠纷、投诉的处理、解决等。
            </li>
            <li>
              （5）向您推送相关业务消息（如财务消息、产品消息、至信链平台动态等）和商业广告。例如，至信链平台可以通过您的手机号码、电子邮箱等联系方式以短信、电话、电子邮件等任何方式向您推送商业广告，宣传、推广至信链平台服务或至信链平台上的其他服务。
            </li>
            <li>
              （6）
              向您提供与您更加相关的服务。例如，基于您的信息统计、分析情况或者至信链平台服务的其他用户数据统计、分析情况，向您提供您可能感兴趣的类似功能或服务等。
            </li>
            <li>（7） 邀请您参与有关我们产品和服务的调查。</li>
            <li>
              （8）
              我们可能将至信链平台用户使用某项或多项至信链平台服务的数据单独或与其他服务的用户使用数据进行整合、分析等，形成统计类用户报告、商业分析等资料（统称“统计资料”），在至信链平台内部或外部共享、发布、展示等，我们会确保统计资料不会涉及可以识别特定用户身份的信息。
            </li>
            <li>
              （9）
              其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。
            </li>
          </ul>
        </div>

        <div class="con">
          <h3>5 我们如何使用您的信息</h3>
          <h4>5.1【信息传输共享】</h4>
          <p>
            未经您同意，我们不会与任何无关第三方共享您的信息，以下情形除外：
          </p>
          <ul>
            <li>
              （1）
              我们可能将您的信息与我们的关联公司、第三方服务提供商、服务商及代理共享，仅用作下列用途。
            </li>
            <li>
              （2）提供“2、我们收集的信息”中的相应功能或服务所必需，以及出于“4、我们如何使用收集的信息”中的部分所述目的所必需。
            </li>
            <li>
              （3）履行我们在《至信链平台用户服务协议》或本政策中的义务、行使我们的权利。
            </li>
            <li>
              （4）为提供更好的服务，我们可能委托第三方服务提供商、服务商及代理（统称“合作伙伴”）向您提供服务，包括但不限于咨询顾问服务、客户服务、产品推荐、技术服务等，因此，为向您提供服务所必需，我们会与合作伙伴共享您的某些信息。例如，为完成身份验证，为向您提供更加合适的产品、服务等。
            </li>
            <li>
              （5）对于由我们与合作伙伴共同研发、提供的产品、服务，为实现您使用该产品、服务的目的，我们可能需要向合作伙伴共享您的部分信息。
              如我们与上述任何第三方共享您的信息，我们仅会基于合法、正当、必要原则，在为提供服务所必需的范围内与其共享您的信息，并且我们将努力确保第三方在使用您的信息时遵守本政策及我们要求其遵守的其他适当的保密和安全措施，承诺不得将您的信息用于其他任何用途。
            </li>
          </ul>
          <h4>5.2.【数据转移】</h4>
          <h4>
            5.3.
            随着我们业务的持续发展，我们有可能进行分立、合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们因前述原因转移您的信息时，将遵守相关法律法规的要求，履行相应责任和义务。
          </h4>
          <h4>5.4【共享、转让、公开披露的默认情形】</h4>
          <p>
            根据相关法律等规定，在以下情形中，我们可以不经您同意而共享、转让、公开披露您的信息：
          </p>
          <ul>
            <li>
              （1） 履行法律法规规定的义务相关的，例如执行有权机关的要求。
            </li>
            <li>（2）与国家安全、国防安全直接相关的。</li>
            <li>（3）与公共安全、公共卫生、重大公共利益直接相关的。</li>
            <li>（4）与刑事侦查、起诉、审判和判决执行等直接相关的。</li>
            <li>
              （5）出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的。
            </li>
            <li>（6）用户自行向社会公众公开的个人信息。</li>
            <li>
              （7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
            </li>
          </ul>
        </div>

        <div class="con">
          <h3>6 信息安全</h3>
          <h4>6.1【信息存储的地点】</h4>
          <p>
            我们会按照相关法律法规的规定，将在中国大陆收集的用户信息存储于中国大陆境内。
          </p>
          <h4>6.2【信息存储的时间】</h4>
          <p>
            我们通常仅在为您提供服务期间保留您的信息，保存期限不会超过满足相关使用目的所必需的期限。但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：
          </p>
          <ul>
            <li>（1） 遵守适用的法律法规等有关规定。</li>
            <li>（2）遵守法院判决、裁定或其他法律程序的要求。</li>
            <li>（3）遵守相关行政、司法机关或其他有权机关的要求。</li>
            <li>
              （4）为执行相关服务协议或本政策、处理投诉/纠纷，或者是保护他人的人身和财产安全或合法权益所合理必需的。
            </li>
          </ul>
          <h4>6.3【信息安全保障】</h4>
          <p>
            我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。
          </p>
          <ul>
            <li>
              （1）我们使用各种安全技术以保障信息的安全。例如，我们将努力采取加密等安全保护措施，防止用户信息遭到未经授权的访问或修改、泄露、毁损或丢失。
            </li>
            <li>
              （2）我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，并进行审计，要求他们遵守保密义务。
            </li>
            <li>
              （3） 我们非常重视信息安全合规工作，并通过众多国际和国内的
              安全认证，如 ISO 27018
              公有云个人信息保护认证、网络安全等级保护认证、ISO 27001
              信息安全管理体系认证等，以业界先进的解决方案充分保障您的信息安全。
            </li>
            <li>
              （4）
              若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等法律法规的要求及时上报，并以发送电子邮件、推送通知、公告等形式告知您相关情况，并向您给出安全处理建议。
            </li>
          </ul>
          <h4>6.4【安全责任】</h4>
          <p>
            为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识和措施。我们仅在因至信链平台过错直接导致您信息泄露的范围内承担责任，请您妥善保管您的账号及密码信息，避免您的信息泄露。至信链平台账号有安全保护功能，但您也需要妥善保护自己的信息，除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等信息。
          </p>
        </div>

        <div class="con">
          <h3>7 您如何管理自己的信息</h3>
          <h4>7.1【信息管理】</h4>
          <ul>
            <li>
              （1）您可以访问、修改和删除您的用户信息。为保障您的账号安全、合法权益等，不同的信息访问、修改和删除可能有不同的要求（例如变更实名认证信息需要进行身份验证），并且，基于技术逻辑、法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除（例如您无法更改分配的账号
              ID）。
            </li>
            <li>
              （2）在您注册完成后，您可以对账号信息进行完善、修改等，例如完善行业信息可为您提供更精准的行业优惠及活动信息。
            </li>
            <li>
              （3）您可以修改消息订阅设置，选择通过何种方式接收订阅消息或者选择需要订阅的消息的类别。但由于推送的消息可能与您的账号安全、服务使用等息息相关（例如账户欠费通知、产品到期/回收通知等），我们建议您不要取消订阅，避免遗漏消息。
            </li>
            <li>
              （4）
              我们将依照法律法规要求或双方约定收集、使用和存储您的信息，如我们违反法律法规的规定或者双方的约定收集、使用或存储您的信息，我们将进行更正或删除。
            </li>
            <li>
              （5）
              如果我们收集、存储的您的信息有错误，且您无法自行更正的，您可以要求我们进行更正。
            </li>
          </ul>

          <h4>7.2【服务注销】</h4>
          <p>
            您可以注销至信链平台账号。具体操作步骤请查看
            账号注销帮助文档。注销成功后，该账号下所有服务、数据将被删除且无法恢复。
            当您注销账号后，我们将按照相关法律法规的要求保留您的相关信息；超出法定保存期限后，我们将依法删除或匿名化处理您的个人信息。
          </p>
        </div>

        <div class="con">
          <h3>8 其他</h3>
          <h4>8.1【政策更新】</h4>
          <ul>
            <li>
              （1）为了给您提供更好的服务，本隐私政策也会随之更新。我们会通过在至信链官方网站发出更新版本并在生效前通过公告或以其他适当方式提醒您相关内容的更新，也请您访问元子盒钱包以便及时了解最新的隐私政策。
            </li>
            <li>
              （2）对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
            </li>
          </ul>

          <h4>8.2【标题说明】</h4>
          <p>
            各条款前所列索引标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。
          </p>
          <h4>8.3【协议可分性】</h4>
          <p>本协议部分条款被认定为无效时，不影响本协议其他条款的效力。</p>
          <h4>8.4【协议持续性】</h4>
          <p>
            本协议自您勾选并成功注册为本钱包用户之日起生效，除非本钱包终止本协议或者用户丧失本钱包用户资格，否则本协议始终有效。本服务协议终止并不免除用户根据本协议或其他有关协议、规则所应承担的义务和责任。
          </p>
          <h4>8.5【联系我们】</h4>
          <p>
            如您对本用户协议有任何问题、意见或建议，或者需要就个人信息安全进行投诉、举报的，请发送邮件至邮箱：
          </p>
        </div>
      </div>
    </el-dialog>

    <!-- 隐私政策 end -->
  </div>
</template>
<script>
import AppHeader from "@/components/layer/AppHeader"
import AppFooter from "@/components/layer/AppFooter"
import Regstep from "@/components/common/Regstep"
import { setInterval, clearInterval } from "timers"
import { login, register, sendCode, getUserInfo, checkEmail } from "@/api/user"
import { sm3 } from "@/utils/sm3"
import { setCookie, getCookie, delCookie } from "@/utils"
import { redirectUrl, cookieDomainName } from "@/config"

export default {
  components: {
    AppHeader,
    AppFooter,
    Regstep,
  },
  data() {
    return {
      centerDialogVisible: false, // 默认false
      yinsiDialogVisible: false, // 默认false
      emialInfo: {
        type: 2,
        name: "进行中",
      },
      checked: true,
      form: {
        email: "",
        newPass: "",
        newPassComfirm: "",
        vertify: "",
        // user_type:1
      },
      subjectTypes: [
        {
          value: 1,
          label: "法人",
        },
        {
          value: 2,
          label: "自然人",
        },
      ],
      inter: 60,
      isInter: false,
      loading: "",
    }
  },
  methods: {
    validateEmail(value) {
      value =
        typeof value === "undefined" || value === null
          ? ""
          : value.replace(/\s+/g, "")
      value = value.replace(/\s+/g, "")
      if (!value && value !== "0") {
        return false
      }
      const reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (reg.test(value)) {
        return true
      }
      return false
    },
    validatePass(value) {
      if (!value && value !== "0") {
        return false
      }
      const reg = /^(?=.*[a-zA-Z])(?=.*\d)[^\s\n\t]{8,16}$/
      if (reg.test(value)) {
        return true
      }
      return false
    },
    checkEmail() {
      const param = {
        email: this.form.email,
      }
      checkEmail(param).then((res) => {
        this.loading.close()
        this.$message.closeAll()
        if (res.retCode === 0) {
          if (res.exist === 0) {
            this.sendCode()
          } else {
            this.$message.error("用户邮箱已存在")
          }
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    onSubmit() {
      this.$message.closeAll()
      // this.$router.push({path: '/registerSuccess'});
      if (this.form.email == "") {
        this.$message.error("邮箱不能为空")
      } else if (!this.validateEmail(this.form.email)) {
        this.$message.error("邮箱格式不正确")
      } else if (this.form.newPass == "") {
        this.$message.error("密码不能为空")
      } else if (!this.validatePass(this.form.newPass)) {
        this.$message.error("密码格式不正确")
      } else if (this.form.newPassComfirm != this.form.newPass) {
        this.$message.error("两次输入的密码不一致")
      } else if (this.form.vertify == "") {
        this.$message.error("验证码不能为空")
      } else {
        this.register()
      }
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.retCode == 0) {
          localStorage.setItem("userData", JSON.stringify(res.detail))
          const day = new Date()
          day.setTime(day.getTime() + 1 * 24 * 60 * 60 * 1000)
          const expires = day.toGMTString()
          //document.cookie = 'userData=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.zhixinblockchain.com'
          // document.cookie = `userData=${  JSON.stringify(res.detail)  };expires=${  expires  };domain=.zhixinblockchain.com`
          this.$router.push({ path: "/registerSuccess" })
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    getVertify() {
      this.$message.closeAll()
      if (this.form.email == "") {
        this.$message.error("邮箱不能为空")
      } else if (!this.validateEmail(this.form.email)) {
        this.$message.error("邮箱格式不正确")
      } else {
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        })
        this.checkEmail()
      }
    },
    register() {
      const data = {
        email: this.form.email,
        pwd: sm3(this.form.newPass),
        verifyCode: this.form.vertify,
        // user_type:this.form.user_type
        //加上主体类型
      }
      if (!this.checked) {
        this.$message.error("请勾选至信链开放平台服务协议")
        return
      }
      register(data).then((ress) => {
        this.$message.closeAll()
        if (ress.retCode == 0) {
          login(data).then((resss) => {
            this.$message.closeAll()
            if (resss.retCode == 0) {
              localStorage.setItem("token", resss.token)
              if (location.href.includes(window.toolbox_redirectUrl.open)) {
                // 测试环境
                // setCookie("zxtoken", encodeURIComponent(resss.token), ".zhixinblockchain.com");
                setCookie(
                  "zxtoken",
                  encodeURIComponent(resss.token),
                  cookieDomainName
                )
              } else {
                // 本地
                setCookie("zxtoken", encodeURIComponent(resss.token)) // 兼容本地调试
              }
              this.getUserInfo()
            } else {
              this.$message.error(resss.retMsg)
            }
          })
        } else {
          this.$message.error(ress.retMsg)
        }
      })
    },
    toHome() {
      // 根据路由进行判断,返回不同的地方
      const _href = location.href
      if (location.href.indexOf("from=treasurechest") != -1) {
        window.location.href = window.toolbox_redirectUrl.treasurechest
      } else if (location.href.indexOf("from=portal") != -1) {
        window.location.href = window.toolbox_redirectUrl.portal
      } else {
        this.$router.push({
          path: "/",
        })
      }
    },
    sendCode() {
      const data = {
        email: this.form.email,
        type: 1,
      }
      sendCode(data).then((res) => {
        this.$message.closeAll()
        this.loading.close()
        if (res.retCode == 0) {
          this.isInter = true
          const that = this
          const inters = setInterval(() => {
            that.inter--
            if (that.inter <= 0) {
              clearInterval(inters)
              that.isInter = false
              that.inter = 60
            }
          }, 1000)
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.register {
  height: 100%;
  .content {
    .suf {
      position: absolute;
      right: 0;
    }
    .suffix {
      font-style: normal;
      margin-right: 20px;
      font-size: 14px;
      color: #244bb0;
      cursor: pointer;
    }
    .suffix2 {
      font-style: normal;
      margin-right: 20px;
      font-size: 14px;
      color: #b4b4b4;
    }
    ::v-deep .el-form-item {
      margin-bottom: 32px;
    }
    width: 1000px;
    // height: 672px;
    box-shadow: 0px 14px 13px 0px rgba(186, 191, 198, 0.1);
    margin: 0 auto;
    padding-top: 85px;
    .topImg {
      width: 100%;
      height: 157px;
    }
    .form {
      width: 600px;
      text-align: center;
      margin: 50px auto;
    }
    .footBtn {
      width: 100%;
      text-align: center;
      button {
        width: 160px;
        height: 50px;
        font-size: 18px;
        color: #fff;
        background: linear-gradient(
          -90deg,
          rgba(49, 97, 187, 1) 0%,
          rgba(42, 72, 156, 1) 100%
        );
        border-radius: 4px;
        border: none;
      }
    }
    .fontText {
      font-size: 14px;
      color: #2349af;
      text-align: center;
      margin-top: 37px;
      cursor: pointer;
    }
  }
  .agree {
    margin: 0px 0 34px;
    text-align: center;
    ::v-deep .el-checkbox {
      font-weight: bold;
      margin-right: 0;
    }
  }
}
</style>

<style lang="scss">
.user_type {
  .el-select {
    width: 100%;
  }
}
.center-dialog {
  .el-dialog__header {
    text-align: center;
  }
  .protocol-con {
    height: 426px;
    padding: 10px;
    line-height: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 25px;
  }
  .el-dialog__body {
    // border: 1px solid red;
  }
  ul li {
    list-style: none;
    // font-weight: bold;
  }
  .p1 {
    text-indent: 2em;
    margin-bottom: 10px;
  }
  .txt-strong {
    color: #333;
    text-decoration: underline;
  }
  .con {
    margin-bottom: 10px;
    h4 {
      margin-top: 5px;
    }
    p {
      text-indent: 2em;
    }
  }
}
</style>
