// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/reg-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".process-step[data-v-ee80efb6]{padding:0 135px 0 150px;display:flex;height:156px;background-color:#1f4395;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.process-step .process-step-con[data-v-ee80efb6]{flex:0 0 110px;width:110px;display:flex;flex-direction:column;align-items:center;justify-content:center}.process-step .process-step-con .img[data-v-ee80efb6]{width:56px;height:56px}.process-step .process-step-con .tt2[data-v-ee80efb6]{font-size:16px;margin-top:15px;font-family:PingFang SC;font-weight:400;color:#9abff2;line-height:20px}.process-step .process-step-con .tt3[data-v-ee80efb6]{font-size:12px;margin-top:10px;font-family:PingFang SC;font-weight:400;color:#9abff2;line-height:20px}.process-step .process-step-con .active[data-v-ee80efb6]{color:#fff}.process-step .w115[data-v-ee80efb6]{flex:0 0 115px;width:115px}.process-step .process-step-line[data-v-ee80efb6]{margin-top:78px;text-align:center;align-items:center;display:flex;justify-content:center;height:0;flex:1;border-top:1px dashed #9abff2}", ""]);
// Exports
module.exports = exports;
